import{
  makeResponsive,
  placeTitle,
  createSpace,
  placeQuestion,
  placeComment,
  createAxes,
  writeHTMLText,
  drawPoint,
  setSize,
  labelIt,
  placeMarker,
  drawCircle,
  placeImage,
  placeShuffle,
  placeTest,
  drawArrow,
  drawAngle,
  drawSegment,
  placeBWhite,
  placeWhite,
  placeBCircles,
  placeCircles,
  placeChecks,
  placeCross,
  placeExclaim,
  hoverMe,
  placeLogo,
  drawMarker,
  toggle,
  toggleTF,
  placeFingers,
  placeAnswers,
  drawTriangle,
  placeRedo,
  placeStartOver,
  print,
  plotFunction,
  setWidth,
  drawLine,
  placePlay,
  placePause,
  writeMediumText,
  drawDash
} from '../Utils.js'
const Boxes = {
  box1: function () {
///////////////////////////////////GLOBAL SETTINGS//////////////////////
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.line.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
//////////////////////////////////LOCAL SETTINGS//////////////////////////////////
var graph =createSpace(-8,8,-7,9);
makeResponsive(graph);
graph.options.layer['image']=10;
graph.options.layer['line']=10;
graph.options.layer['point']=12;
//graph.options.layer['polygon']=10;
createAxes(graph);
placeTitle(graph, 'Parabola', '(Press the Play Button to Start)');
//var n =placeInput(graph, 6.5, -3.5,'0');
var a=1;
var b=4;
var c=2;
var d=-2;
////////////////////
function f(x){
  return x*x/4
}
function fp(x){
  return 3*a*x*x+2*b*x+c
}
function fpp(x){
  return 6*a*x+2*b
}
/////////////////////////////////
var Pt1 = drawPoint(graph, -6, -1.0);
Pt1.setAttribute({visible:false});
var Pt2 = drawPoint(graph, 6, -1.0);
Pt2.setAttribute({visible:false});
var l =drawLine(graph, Pt1, Pt2);
//l.setAttribute({dash:1});
var Pts =drawPoint(graph, 0, 1);
Pts.setAttribute({color:'black'});
setSize(graph, Pts, 4);
labelIt(graph, Pts, 'F');
/////////////////////////////////////////////////////////
var time =graph.create('slider',[[0, -17],[4, -17],[-4, -4, 4]]);
time.setAttribute({visible:false, strokeWidth:0});
////////////////////////////////////
plotFunction(graph, f, -6, 6);
//var prime =plotFunction(graph, fp, -4, 4);
//prime.setAttribute({strokeColor:'blue'});
//var dprime =plotFunction(graph, fpp, -4, 4);
//dprime.setAttribute({strokeColor:'green'});
//////////////////////////////////
var trace =drawPoint(graph, ()=>time.Value(), ()=>f(time.Value()));
setSize(graph, trace, 4);
trace.setAttribute({color:'green'});
labelIt(graph, trace, 'P');
/////////////////////////////////////////////////
var PtsP = drawPoint(graph, ()=>trace.X(), -1);
setSize(graph, PtsP, 4);
PtsP.setAttribute({color:'blue'});
labelIt(graph, PtsP, 'Q');
///////////////////////////////////////////////////
var PF =drawDash(graph, trace, Pts);
var PQ =drawDash(graph, trace, PtsP);
///////////////////////////////////////////////
var startButton =placePlay(graph, 'left');
startButton.on('down', function(){time.startAnimation(1, 200, 100)})
//startButton.on('down', function(){time.moveTo([0, -7]); time.moveTo([4, -7],1000)});
var pauseButton =placePause(graph, 'right');
pauseButton.on('down', function(){time.stopAnimation()})
////////////////////////////////////////////////////////
//n.on('over', function(){time.moveTo([0,-7])});
var funText =writeHTMLText(graph, 2, -1.5, function(){return 'PF ='+(PF.L()).toFixed(2)});
funText.setAttribute({anchorX:'left'});
var iText = writeMediumText(graph, 2, -3.5, function(){return 'PQ ='+(PQ.L()).toFixed(2)});
iText.setAttribute({anchorX:'left'});
/******************END OF FILE ***************************/
}
}
export default Boxes;
